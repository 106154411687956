<template lang="pug">
  v-layout.auth_social(align-center, justify-center, column, fill-height)
    .process_verification(v-if="showCodeInput")
      h2 {{ $t('hints.enterVerificationCode') }}
      SecureCodeInputs(
        v-model="code",
        :blurOnComplete="true",
        :twoFAType="twoFAType",
        :loginToken="loginToken",
        :errors="errors")
</template>

<script>
import { mapState } from 'vuex';
import SecureCodeInputs from '@/components/SecureCodeInputs';
import { SOCIAL_SIGNIN_ERRORS } from '@/store/modules/user/types';
import { getUnauthUserLocale, getCookie } from '@/utils';
import CONSTANTS from '@/constants';

export default {
  components: { SecureCodeInputs },
  data() {
    return {
      showCodeInput: false,
      code: '',
      loginToken: '',
      errors: [],
      redirectParams: null,
      twoFAType: null
    };
  },
  methods: {
    confirm2fa() {
      const payload = {
        data: {
          type: 'confirm-2-fa',
          attributes: {
            code: this.code,
            login_token: this.loginToken
          }
        }
      };

      this.$store.dispatch('user/confirm2fa', payload)
        .then(() => {
          this.$store.dispatch('user/getUser')
            .then(({ attributes }) => {
              if (!attributes.registration_country_id) {
                this.$router.push({ name: 'complete-signup', replace: true });
              } else {
                this.$store.dispatch('user/signinService', { serviceId: localStorage.getItem('service_id'), router: this.$router });
              }
            });
        })
        .catch(errors => {
          if (errors.length) this.errors = errors;

          if (this.showCodeInput) {
            this.code = '';
            this.$refs.secureCodeInputs.securityCode = new Array(6);
            this.$refs.secureCodeInputs.$el.getElementsByTagName('input')[0].focus();
          }
        });
    }
  },
  computed: {
    ...mapState('user', ['acceptedLastVersions']),
    user() { return this.$store.getters['user/getUser']; }
  },
  watch: {
    code(val) { if (val.length === 6) this.confirm2fa(); },
    showCodeInput(val) {
      if (val) {
        setTimeout(() => this.$refs.secureCodeInputs.$el.getElementsByTagName('input')[0].focus(), 0);
      }
    },
    acceptedLastVersions(val) {
      if (val && this.redirectParams) {
        this.$store.dispatch('user/signinService', this.redirectParams);
      }
    }
  },
  mounted() {
    const socialName = this.$route.params.socialName;
    const { code, state } = this.$route.query;
    const referralHash = localStorage.getItem('referral_hash');
    const language = CONSTANTS.LANGUAGES.find(i => i.val === getUnauthUserLocale());
    const storedState = localStorage.getItem('oauth_state');
    const data = {
      data: {
        type: 'social-networks',
        attributes: {
          access_code: code,
          name: socialName,
          language_id: language.id
        }
      }
    };
    this.$store.dispatch('user/setImgName', 'twoFAT');
    if (state !== storedState) {
      this.$router.replace({ name: 'signin' });
      return;
    }

    if (referralHash) {
      data.data.attributes.referral_hash = referralHash;
    }

    if (localStorage.getItem('service_id')) {
      data.relationships = {
        service: {
          data: {
            type: 'services',
            id: Math.abs(Number(localStorage.getItem('service_id')))
          }
        }
      };
    }

    const fbp = getCookie('_fbp');
    const fbc = getCookie('_fbc');

    if ((fbp || fbc) && !data.relationships) data.relationships = {};

    if (fbp) {
      data.relationships.fbp = {
        data: {
          id: fbp,
          type: 'fbps'
        }
      };
    }

    if (fbc) {
      data.relationships.fbc = {
        data: {
          id: fbc,
          type: 'fbcs'
        }
      };
    }

    if (localStorage.getItem('access_token')) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('session_id');
    }

    this.$store.dispatch('user/signinSocial', data)
      .then(res => {
        this.$store.commit(`user/${SOCIAL_SIGNIN_ERRORS}`, null);

        if (res.login_token) {
          this.loginToken = res.login_token;
          this.errors = [];
          this.twoFAType = res['2fa_type'];
          this.showCodeInput = true;

          this.$eventHub.$emit('socialSigninResolved');
        } else {
          this.$store.dispatch('user/getUser')
            .then(({ attributes }) => {
              this.redirectParams = {
                serviceId: localStorage.getItem('service_id'),
                router: this.$router
              };

              const date = new Date(attributes.created_at);
              const diff = Date.now() - date.getTime();

              if (diff > 10 * 1000 && attributes.first_name && attributes.last_name && attributes.registration_country_id) {
                if (referralHash) this.redirectParams.referralHash = referralHash;

                if (this.acceptedLastVersions) {
                  this.$store.dispatch('user/signinService', this.redirectParams)
                    .finally(() => this.$eventHub.$emit('socialSigninResolved'));
                } else {
                  this.$eventHub.$emit('socialSigninResolved');
                }
              } else {
                this.$router.push({ name: 'complete-signup', replace: true, params: { type: socialName } });
              }
            })
            .catch(errors => {
              this.$store.commit(`user/${SOCIAL_SIGNIN_ERRORS}`, errors);
              this.$router.replace({ name: 'signin' });
            });
        }
      })
      .catch(errors => {
        if (errors.message) {
          this.$eventHub.$emit('socialSigninResolved');
          this.errors = [{ title: this.$t('messages.error.undefined'), detail: errors.message }];
        }

        if (errors.length) {
          this.$store.commit(`user/${SOCIAL_SIGNIN_ERRORS}`, errors);
          this.$router.replace({ name: 'signin' });
        }
      })
      .finally(() => localStorage.removeItem('oauth_state'));
  },
  beforeRouteLeave(to, from, next) {
    this.$eventHub.$emit('socialSigninResolved');
    next();
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .auth_social {
    padding: 0;
    margin: auto;
    text-align: left;
    @media screen and (max-width: 530px) {
      padding: 0 20px;
    }
    .process_verification {
      margin-bottom: 50px;
      h2 {
        font-size: 36px;
        color: $black2;
        margin: 199px 0 30px;
        @media screen and (max-width: 530px) {
          margin: 20px 0;
          text-align: left;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
        }
      }
    }

    section {
      max-width: 550px;
      position: relative;
      padding-left: 80px;
      width: 100%;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 30px;
      text-align: center;
    }

    p {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 500;
      color: $light-theme-primary-text;
      text-align: left;

      .svg_check {
        position: absolute;
        left: 0;

        ::v-deep path { fill: $green !important; }
      }
      @media screen and (max-width: 400px) { font-size: 28px; }
    }

    .main_link {
      margin-top: 15px;
      white-space: nowrap;
      &:first-of-type { margin-top: auto; }
    }

    .errors {
      margin-top: 15px;
      color: $error-color;
    }
  }
</style>
